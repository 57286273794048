<template>
    <div class="content-wrapper">
        <PageHeader screenName="Entrada de vacinas" :linkItems="linkItems"/>
        <main class="card hidden_overflow_tabela">
            <b-row class="p-2">
                <b-col lg="4" md="6" sm="12">
                    <b-button @click="redirectToRegister()" variant="primary-button">
                   <feather-icon
                       icon="PlusIcon"
                       class="mr-50"  
                   />
                   <span class="align-middle">Nova Entrada</span>
               </b-button>
               </b-col>
            </b-row>
           <b-row class="pt-1 px-2">
               <b-col lg="3" md="6" sm="12">
                    <b-form-group 
                    label="Unidade Operacional"
                    label-for="unidade-operacional-input"
                    label-class="font_size_label_estoque"
                    >
                        <v-select 
                        id="unidade-operacional-input"
                        v-model="search.unidade"
                        :options="unidadeSelect"
                        label="descricao_unidade"
                        placeholder="Selecione uma unidade"
                        :clearable="true"
                        >
                        <span slot="no-options">Nenhuma opção selecionável.</span>
                        </v-select>
                    </b-form-group>
               </b-col>
               <b-col lg="3" md="6" sm="12">
                    <b-form-group 
                    label="Número da nota fiscal"
                    label-for="nota-fiscal-input"
                    label-class="font_size_label_estoque"
                    >
                        <b-form-input
                            v-model="search.nota_fiscal"
                            id="nota-fiscal-input"
                            placeholder="Digite o número da nota fiscal"
                        />
                    </b-form-group>
               </b-col>
               <b-col xl="3" lg="3" md="6" sm="12">
                    <b-form-group 
                        label="Emissão da nota"
                        label-for="emissao-input"
                        label-class="font_size_label_estoque"
                    >
                        <b-input-group>
                            <b-form-input
                                class="form-control"
                                id="emissao-input"
                                v-model="input.emissao"
                                v-mask="'##/##/####'"
                                placeholder="00/00/0000"
                                autocomplete="off"
                                @input="setEmissaoInput"
                            />
                            <b-input-group-append>
                                <b-form-datepicker
                                    class="form-control d-flex align-items-center"
                                    locale="pt-BR"
                                    v-model="search.emissao"
                                    button-only
                                    right
                                    :hide-header="true"
                                    label-help="Selecione a data de emissão"
                                    button-variant="custom-blue"
                                    clearable
                                    @input="setEmissaoPicker"
                                />
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
               </b-col>
               <b-col xl="3" lg="3" md="6" sm="12">
                    <b-form-group 
                        label="Data do lançamento"
                        label-for="lancamento-input"
                        label-class="font_size_label_estoque"
                    >
                        <b-input-group>
                            <b-form-input
                                class="form-control"
                                id="lancamento-input"
                                v-model="input.lancamento"
                                v-mask="'##/##/####'"
                                placeholder="00/00/0000"
                                autocomplete="off"
                                @input="setLancamentoInput"
                            />
                            <b-input-group-append>
                                <b-form-datepicker
                                    class="form-control d-flex align-items-center"
                                    locale="pt-BR"
                                    v-model="search.lancamento"
                                    button-only
                                    right
                                    :hide-header="true"
                                    label-help="Selecione a data de lançamento"
                                    button-variant="custom-blue"
                                    clearable
                                    @input="setLancamentoPicker"
                                />
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
               </b-col>
               <b-col lg="6" md="6" sm="12">
                    <b-form-group 
                    label="Fornecedor"
                    label-for="fornecedor-input"
                    label-class="font_size_label_estoque"
                    >
                        <v-select
                        id="select-fornecedor"
                        multiselect
                        multiple
                        v-model="search.fornecedores"
                        variant="custom"
                        item-text="descricao"
                        item-value="id_empresa"
                        label="nome_empresa"
                        placeholder="Selecione um ou mais"
                        :values="fornecedorSelect"
                        :options="fornecedorSelect"
                        :dense="true"
                        >
                        <span slot="no-options">Nenhuma opção selecionável.</span>
                        </v-select>
                    </b-form-group>
               </b-col>
               </b-row>
               <b-row class="justify-content-center">
                   <b-button @click="filter" variant="primary-button">
                       <feather-icon
                           icon="SearchIcon"
                           class="mr-50"  
                       />
                       <span class="align-middle">Pesquisar</span>
                   </b-button>
 
                   <b-button type="reset" class="ml-2 cor_botao" @click="cleanSearch" variant="outline-primary">
                       <feather-icon
                           icon="XIcon"
                           class="mr-50"  
                       />
                       <span class="align-middle">Limpar</span>
                   </b-button>
           </b-row>
            <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <hr>
            <div>
                <section class="filters pb-1 px-2" v-if="!loadingData">
                    <b-row align-h="between">
                        <b-col lg="3" md="4" sm="5" class="col-12 mb-1 mb-sm-0">
                            <div class="d-flex justify-center-center align-items-center">
                                <span class="mr-1" for="tableSize">Mostrar</span>
                                <v-select 
                                    id="tableSize"
                                    v-model="paginationData.defaultSize"
                                    @input="updateTableSize($event)"
                                    :options="table.optionsSize"
                                    :clearable="false"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </section>
                <section class="list-colaborador-import" v-if="!loadingData">
                    <b-table
                        id="tableColaboradorImport"
                        responsive
                        :stickyColumn="true"
                        :busy.sync="filtering"
                        :fields="table.fields"
                        :items="table.items"
                        @sort-changed="ordenarColuna"
                        no-local-sorting
                        :no-sort-reset="true"
                    >
                        <template #cell(numero)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                            >
                                {{ row.item.numero }}
                            </span>
                        </template>
                        <template #cell(emissao)="row">
                            <span class="font-weight-bold">
                                {{ formatDate(row.item.emissao, 'DD/MM/YYYY') }}
                            </span>
                        </template>
                        <template #cell(criacao)="row">
                            <span class="font-weight-bold">
                                {{ formatDate(row.item.lancamento, 'DD/MM/YYYY') }}
                            </span>
                        </template>
                        <template #cell(id_fornecedor)="row">
                            <span class="font-weight-bold">
                                {{ row.item.fornecedor }}
                            </span>
                        </template>
                        <template #cell(id_unidade)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                {{ row.item.unidade }}
                            </span>
                        </template>
                        <template #cell(actions)="row">
                            <feather-icon
                                id="entrada-show"
                                role="button"
                                class="text-custom-blue"
                                icon="EyeIcon"
                                size="18"
                                @click.prevent="redirectToShow(row.item)"
                            />
                        </template>
                    </b-table>
                    <div class="pb-2 px-1">
                        <span v-if="without" class="ml-1">Nenhuma entrada encontrada</span>
                        <b-col sm="12" class="mt-2">
                            <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
                        </b-col>
                    </div>
                </section>
            </div>
        </main>
    </div>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, VBTooltip, BSpinner, BCard,  BFormDatepicker, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';
import vSelect from 'vue-select';
import moment from 'moment';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import InfograficoEstoqueCard from '@/views/components/custom/cards/InfograficoEstoqueCard.vue';
import InfoCard from '@/views/components/custom/cards/InfoCard.vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
    title: 'Lista de entrada',
    
    components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    TableSwitch,
    BCard,
    vSelect,
    CustomPagination,
    PageHeader,
    TimedAlert,
    VuePerfectScrollbar,
    BSpinner,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    InfograficoEstoqueCard,
    InfoCard
},

    directives: {
        'b-tooltip': VBTooltip
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Estoque',
                    routeName:'estoque-list'
                },
                {
                    name: 'Entrada de vacinas',
                    active: true
                }
            ],
            alert: {
                errorMessage: '',
                show: false
            },
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            timeID: null,
            filtering: false,
            loadingData: false,
            without: false,
            searchTerm: '',
            table: {
                fields: [
                    {key: "numero", label: "N° NOTA FISCAL", sortable: true, class: 'mw-200'},
                    {key: "emissao", label: "DATA DA EMISSÃO", sortable: true, class: 'mw-250'},
                    {key: "criacao", label: "DATA DO LANÇAMENTO", sortable: true, class: 'mw-250'},
                    {key: "id_fornecedor", label: "FORNECEDOR", sortable: true, class: 'mw-200'},
                    {key: "id_unidade", label: "U. Operacional", sortable: true, class: 'mw-200'},
                    {key: "actions", label: "AÇÕES", sortable: false, class: 'mw-50 text-center col-1'}
                ],
                items: [],
                optionsSize: [10, 25, 50, 100],
                columnOrder: 'id_nota_fiscal',
                order: 'desc'
            },
            paginationData: {
                currentPage: 1,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10,
            },
            search: {
                unidade: '',
                nota_fiscal: '',
                emissao: '',
                lancamento: '',
                fornecedores: [],
            },
            input: {
                emissao: '',
                lancamento: '',
            },
            unidadeSelect: [],
            fornecedorSelect: [],
            data: {}
        }
    },
    mounted() {
        this.loadAllInfo();
    },

    methods: {
        async loadAllInfo() {
            const parameters = {
                ativo: true,
            }
            await this.$http.get(this.$api.unidade(), { params: parameters }).then(({ data }) => {
                this.unidadeSelect = data;
            });
                
            await this.$http.get(this.$api.saveAndGetEmpresa(), {params: {tipo: 'Fornecedor', todos: true, paginacao:false}}).then(({ data }) => {
            this.fornecedorSelect = data;
            });
        },
        loadData(filterData = null) {
            this.paginationData.defaultSize = filterData ? filterData.defaultSize : 10;

            const parameters = {
                id_unidade: this.search.unidade ? this.search.unidade.id_unidade : '',
                nota_fiscal: this.search.nota_fiscal,
                emissao: this.search.emissao,
                lancamento: this.search.lancamento,
                fornecedor: this.formatFornecedorSearch(this.search.fornecedores),
                termoPesquisa: filterData ? filterData.searchTerm : '',
                tamanho: this.paginationData.defaultSize,
                pagina: this.paginationData.currentPage,
                colunaOrdenar: this.table.columnOrder,
                ordem: this.table.order
            };

            this.filtering = true;

            this.$http.get(this.$api.notaFiscal(), { params: parameters }).then(({ data }) => {
                this.table.items = Object.values(data.data);

                this.without = false;
                if(this.table.items.length == 0) {
                    this.without = true;
                }

                this.loadingData = false;

                this.filtering = false;
                this.startPagination(data);
            })
        },
        startPagination(data) {
            this.paginationData.currentPage = data.current_page;
            this.paginationData.totalLines = data.total;
            this.paginationData.fromLine = data.from;
            this.paginationData.toLine = data.to;
        },

        ordenarColuna(sort) {
            this.table.columnOrder = sort.sortBy;
            this.table.order = sort.sortDesc ? 'desc' : 'asc';

            this.filtering = true;

            this.loadData();
        },

        updateCurrentPage(page) {
            this.paginationData.currentPage = page;
            this.filtering = true;
            this.loadData();
        },

        updateTableSize(event) {
            if(!event) {
                this.paginationData.defaultSize = 10;
            }
            this.filtering = true;

            this.paginationData.currentPage = 1;
            this.loadData();
        },
        filter() {
            if(this.searchTerm.length >= 2 || this.searchTerm.length == 0) {
                this.withoutColab = false;
                
                clearTimeout(this.timeID);
                this.timeID = setTimeout(() => {
                    this.filtering = true;
                    this.loadData();
                }, 500);
            }
        },
        cleanSearch(){ 
            this.search.unidade = '';
            this.search.nota_fiscal = '';
            this.search.emissao = '';
            this.input.emissao = '';
            this.search.lancamento = '';
            this.input.lancamento = '';
            this.search.fornecedores = [];
        },
        formatString(string){
            const formatted = string.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z])/g, '');
            return formatted.toLowerCase();
        },
        formatDate(string, format){
            return moment(string).format(format);
        },
        formatFornecedorSearch(data){
            let arrayFornecedor = [];
            if(data){
                data.map(fornecedor =>{
                    arrayFornecedor.push(fornecedor.id_empresa);
                })
            }
            return arrayFornecedor;
        },
        redirectToRegister() {
           this.$router.push({name: 'entrada-register'});
       },
       redirectToShow(item) {
            this.$router.push(
                { 
                    name: 'entrada-show', 
                    params: {
                        idNotaFiscal: item.id_nota_fiscal
                    }
                }
            );
        },
        setEmissaoPicker() {
            this.input.emissao = moment(this.search.emissao).format('DD/MM/YYYY');
        },
        setEmissaoInput() {
            this.search.emissao = moment(this.input.emissao, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        setLancamentoPicker() {
            this.input.lancamento = moment(this.search.lancamento).format('DD/MM/YYYY');
        },
        setLancamentoInput() {
            this.search.lancamento = moment(this.input.lancamento, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
    }
    
}
</script>

<style>
.font_size_label_estoque{
    font-size: 15px;
    margin-top: -5px;
}
.custom{
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
.mono{
    background-color: #f3eeff;
    color: #8c65e2;
}
.multi{
    background-color: #e3f6f4;
    color: #21a696;
}
.cor_botao{
    color: #2772C0 !important;
    border: 1px solid #2772C0 !important;
}
.altura_max_scrollbar{
    max-height:100px;
    padding-right: 15px;
}
.comprimento_maximo_celulas{
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}
.hidden_overflow_tabela{
    overflow: hidden;
}

div#select-fornecedor > ul {
    height: 200px !important;
}
</style>